import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { default as dayjs } from 'dayjs'
import { Validator } from '@/App/Mixins'
import SelectDashboard from '@/App/Components/DashboardSelect/Dashboard-Select.vue'
import WidgetSelect from '@/App/Components/WidgetSelect/Widget-Select.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import {
  REPORT_TYPES_ITEMS,
  TIME_INTERVAL_ITEMS,
  REPORT_DEFAULT
} from '@/App/Pages/Reports/ReportSettingsDialog/constants'
import { DateTimePicker } from '@/App/Components/Pickers'
import GeofenceSelect from '@/App/Components/GeofenceSelect/Geofence-Select.vue'
import LocationDeviceSelect from '@/App/Components/LocationDeviceSelect/Location-Device-Select.vue'
import CalculatorSelect from '@/App/Components/CalculatorSelect/Calculator-Select.vue'
import { ReportService } from '@/App/Services/Report.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ReportType } from '@/types'


const sharedStore = namespace('Shared')

@Component({
  components: { DateTimePicker, SelectDashboard, WidgetSelect, DeviceSelect, GeofenceSelect, LocationDeviceSelect, CalculatorSelect }
})
export default class ReportSettingsDialog extends Mixins(Validator) {
  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  public isOpen = false
  public form = false
  public isLoading = false
  public reportTypesItems = REPORT_TYPES_ITEMS
  public timeIntervalItems = TIME_INTERVAL_ITEMS
  public report = REPORT_DEFAULT

  public open(): void {
    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.report = REPORT_DEFAULT
  }

  public async onGenerate() {
    try {
      this.isLoading = true

      const { from, to } = this.getDateTime()
      // TODO delete unused props for report types like a dataInterval use only at sensorTracing report
      const report = await ReportService.fetch({
        type: this.report.type,
        dashboardId: this.report.dashboardId || '',
        widgetsIds: (this.report.widgetsIds || []).join(','),
        devicesIds: Array.isArray(this.report.devicesIds) ? (this.report.devicesIds || []).join(',') : '',
        geofencesIds: (this.report.geofencesIds || []).join(','),
        from,
        to,
        dataInterval: this.report.dataInterval,
        deviceId: Array.isArray(this.report.devicesIds) ? null : this.report.devicesIds,
        minDuration: this.report.minDuration,
        calculatorId: this.report.calculatorId
      })

      this.setNotification({ color: 'success', text: this.$t('reportsPage.reportSettingsDialog.reportGeneratingNotificationText').toString() })

      this.close()

      this.$emit('onReportGenerated', { type: this.report.type, data: report, from, to })
    } catch (e) {console.log(e)} finally {
      this.isLoading = false
    }
  }

  private getDateTime(): { from: number, to: number } {
    let from: number
    let to: number

    switch (this.report.timeInterval) {
      case 'today':
        from = dayjs().startOf('day').unix()
        to = dayjs().unix()
        break
      case 'yesterday':
        from = dayjs().subtract(1, 'days').startOf('day').unix()
        to = dayjs().subtract(1, 'days').endOf('day').unix()
        break
      case 'week':
        from = dayjs().startOf('week').unix()
        to = dayjs().endOf('week').unix()
        break
      case 'custom':
        from = this.report.dateFrom
        to = this.report.dateTo
        break
    }

    return { from, to }
  }


  get isDatetimepickerShow(): boolean {
    return this.report.timeInterval === 'custom'
  }

  get isSelectDashboardShow(): boolean {
    const exclude: ReportType[] = ['consumption', 'notificationLog', 'AIThermalReportDetailed', 'journey', 'geofences', 'stops', 'parking', 'fuel', 'lastSensorValue']
    return !exclude.includes(this.report.type)
  }

  get isShowIntervalShow(): boolean {
    return this.report.type === 'sensorTracing'
  }

  get isDeviceSelectShow(): boolean {
    const availableTypes: ReportType[] = ['consumption', 'AIThermalReportDetailed', 'journey', 'fuel']
    return availableTypes.includes(this.report.type)
  }
}


