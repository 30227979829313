<template>
	<tr class="app-bg-color-main">
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.end | date('DD.MM.YYYY HH:mm:ss')}}
		</td>
		<td>
			{{item.before}}
		</td>
		<td>
			{{item.after}}
		</td>
		<td>
			{{item.refuel || item.dispense}}
		</td>
		<td>
			<v-btn color="primary" block @click="$emit('openMap', item.position)">
				{{item.position.join(' ')}}
			</v-btn>
		</td>
	</tr>
</template>

<script>
export default {
  name: 'Fuel-Table-Body',
  props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />