<template>
	<tr>
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.widgetName}}
		</td>
		<td>
			{{item.lastUpdateTime | date('DD.MM.YYYY HH:mm:ss')}}
		</td>
		<td :style="{color: item.color}">
			{{item.lastValue}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Last-Sensor-Value-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
