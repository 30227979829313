import { ChartOptions } from 'chart.js'

export const LINE_CHART_DEFAULT_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  scales: {
    xAxes: [],
    yAxes: []
  },
  elements: {
    line: {
      tension: 0
    }
  }
}
