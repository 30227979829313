<template>
	<tr>
		<td>
			{{item.timestamp}}
		</td>
		<td>
			{{item.personUuid}}
		</td>
		<td>
			{{item.userId}}
		</td>
		<td>
			{{item.name}}
		</td>
		<td>
			{{item.groupId}}
		</td>
		<td>
			{{item.attrGender}}
		</td>
		<td>
			{{item.gender}}
		</td>
		<td>
			{{item.attrAge}}
		</td>
		<td>
			{{item.age}}
		</td>
		<td :class="{'red--text': item.Temperature > 37.4}">
			{{item.Temperature}}
		</td>
		<td>
			{{item.Mask}}
		</td>
		<td>
			{{item.status}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'AI-Thermal-Report-Detailed-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
