<template>
	<tr>
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.widgetName}}
		</td>
		<td>
			{{item.dateTime}}
		</td>
		<td :style="{color: item.color}">
			{{item.value}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Sensor-Tracing-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
