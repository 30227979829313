import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ReportService } from '@/App/Services/Report.service'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component
export default class TableHeader extends Vue {
  @sharedStore.Mutation
  private setNotification: (notification: Notification) => void

  @Prop({ required: true })
  private reportName: string

  @Prop({ default: false })
  private isChartBtnShow: boolean

  @Prop({ required: true })
  public readonly calculatorId: number

  public isSearchActive = false
  public actions: ActionBtn[] = [
    { icon: 'far fa-file-excel', tooltip: this.$t('reportsPage.reportsTable.tableHeader.exportToExcelTooltipText').toString(), method: () => this.onExport('excel') },
    { icon: 'far fa-file-pdf', tooltip: this.$t('reportsPage.reportsTable.tableHeader.exportToPdfTooltipText').toString(), method: () => this.onExport('pdf') },
    { icon: 'fa fa-print', tooltip: this.$t('reportsPage.reportsTable.tableHeader.onPrintTooltipText').toString(), method: () => this.onPrint() },
    { icon: 'fa fa-plus', tooltip: this.$t('reportsPage.reportsTable.tableHeader.generateNewReportTooltipText').toString(), event: 'onNewReport' }
  ]

  public async onExport(type: 'excel' | 'pdf'): Promise<void> {
    try {
      this.$emit('onLoading')

      await ReportService.download(type, this.reportName)
    } catch {} finally {
      this.$emit('onLoading')
    }
  }

  public async onPrint(): Promise<void> {
    try {
      this.$emit('onLoading')

      await ReportService.print()
    } catch {} finally {
      this.$emit('onLoading')
    }
  }

  @Watch('isChartBtnShow')
  private isChartBtnShowChanged(val: boolean): void {
    if (val) {
      this.actions.splice(3, 0, { icon: 'fa fa-chart-line', tooltip: 'Chart', method: () => { this.$emit('openChart') } })
    } else {
      this.actions.splice(3, 1)
    }
  }


}

interface ActionBtn {
  icon: string
  tooltip: string
  event?: string
  method?(): Promise<void> | void
}
