import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'
import { DeviceService } from '@/App/Services/Device.service'

const sharedStore = namespace('Shared')

@Component
export default class LocationDeviceSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop({ default: false })
  public readonly required!: boolean

  @Prop({ default: false })
  public readonly multiple!: boolean

  @Prop({ default: false })
  public dark: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public deviceId: number | number[] | string | string[] = []
  public deviceItems: VuetifySelect[] = []
  public isLoading = false

  @Watch('value')
  private valueChanged(val: string): void {
    if (this.multiple) {
      this.deviceId = []
      this.deviceId = val
    } else {
      this.deviceId = ''
      this.deviceId = +val
    }
  }

  @Watch('deviceItems')
  private deviceItemsChanged(): void {
    if (this.multiple) {
      this.deviceId = []
      this.deviceId = this.value
    } else {
      this.deviceId = ''
      this.deviceId = this.value || ''
    }
  }

  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    if (!this.multiple && !Array.isArray(this.deviceId)) {
      return this.validator.isEmpty(this.deviceId)
    } else {
      if (Array.isArray(this.deviceId) && !this.deviceId.length) {
        return this.$t('locationDeviceSelectComponent.validationError').toString()
      } else {
        return true
      }
    }
  }

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      const devices = await DeviceService.fetch()

      this.deviceItems = devices.map(d => {
        return { value: d._id, text: d.name }
      })
      if (this.multiple) {
        this.deviceId = []
        this.deviceId = this.value
      } else {
        this.deviceId = ''
        this.deviceId = this.value || ''
      }
    } catch {} finally {
      this.isLoading = false
    }
  }
}
