<template>
	<tr>
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.widgetName}}
		</td>
		<td>
			{{item.averageValue}}
		</td>
		<td>
			{{item.lowestValue}}
		</td>
		<td>
			{{item.highestValue}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Summary-Information-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
