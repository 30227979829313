<template>
	<tr>
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.widgetName}}
		</td>
		<td>
			{{item.openedTime}}
		</td>
		<td>
			{{item.closedTime}}
		</td>
		<td>
			{{item.openedDuration}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Door-Sensor-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
