import { render, staticRenderFns } from "./Sensor-Tracing-Table-Body.vue?vue&type=template&id=4456da4e&scoped=true&"
import script from "./Sensor-Tracing-Table-Body.vue?vue&type=script&lang=js&"
export * from "./Sensor-Tracing-Table-Body.vue?vue&type=script&lang=js&"
import style0 from "./styles.scss?vue&type=style&index=0&id=4456da4e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4456da4e",
  null
  
)

export default component.exports