<template>
	<tr>
		<td>
			{{item.powerMeterId}}
		</td>
		<td>
			{{item.consumedKwH}}
		</td>
		<td>
			{{item.amount}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Energy-Consumption-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
