import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { WidgetService } from '@/App/Services/Widget.service'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult, WidgetSensorType } from '@/types'
import { Widget } from '@/store/interfaces/widget.interface'

const sharedStore = namespace('Shared')

@Component
export default class WidgetSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop()
  private readonly dashboardId?: string

  @Prop({ default: false })
  private readonly required!: boolean

  @Prop({ default: false })
  public readonly multiple!: boolean

  @Prop({ default: false })
  public readonly clearable!: boolean

  @Prop({ default: '' })
  private readonly sort!: WidgetSensorType

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public widgetId: string | string[] = []
  public widgetsItems: VuetifySelect[] = []

  private async setWidgets(): Promise<void> {
    let widgets: Widget[] = []

    if (this.dashboardId) {
      try {
        this.isLoading = true
        widgets = await WidgetService.fetchByDashboardId(this.dashboardId)
      } catch {} finally {
        this.isLoading = false
      }
    } else {
      try {
        this.isLoading = true
        widgets = await WidgetService.findAll()
      } catch {} finally {
        this.isLoading = false
      }
    }

    if (this.sort) {
      widgets = widgets.filter(w => w.sensorType === this.sort)
    }

    this.widgetsItems = widgets.map(w => ({
      value: w._id,
      text: w.name
    }))

    if (this.widgetsItems.length && !this.value) {
      if (!this.multiple) {
        this.widgetId = this.widgetsItems[0].value.toString()

        this.$emit('input', this.widgetId)
      }
    }
  }

  public onSelectAll(): void {
    this.widgetId = []
    // @ts-ignore
    this.widgetsItems.forEach(w => this.widgetId.push(w.value))

    this.$emit('input', this.widgetId)
  }

  public onWidgetSelected(): void {
    this.$emit('input', this.widgetId)
  }

  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    if (!this.multiple && !Array.isArray(this.widgetId)) {
      return this.validator.isEmpty(this.widgetId)
    } else {
      if (Array.isArray(this.widgetId) && !this.widgetId.length) {
        return this.$t('widgetSelectComponent.validationError').toString()
      } else {
        return true
      }
    }
  }

  @Watch('value')
  private valueChanged(val: string): void {
    if (this.multiple) {
      this.widgetId = []
      this.widgetId = val
    } else {
      this.widgetId = ''
      this.widgetId = val
    }
  }

  @Watch('dashboardId')
  private async dashboardChanged(val: string): Promise<void> {
    try {
      if (val) {
       await this.setWidgets()
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  private mounted(): void {
    if (this.multiple) {
      this.widgetId = []
      this.widgetId = this.value
    } else {
      this.widgetId = ''
      this.widgetId = this.value
    }

    this.setWidgets()
  }
}
