
import { Component, Watch, Prop, Mixins } from 'vue-property-decorator'
import { ChartData, ChartOptions } from 'chart.js'
import { Line, mixins } from 'vue-chartjs'
import 'chartjs-plugin-zoom'
import { LINE_CHART_DEFAULT_OPTIONS } from '@/App/Components/Charts/constants'

@Component
export default class LineChart extends Mixins(Line, mixins.reactiveProp) {
	@Prop({ required: true })
	public chartData: ChartData

	@Prop({ default: {} })
	public customOptions: ChartOptions

	// @ts-ignore
  public options: ChartOptions = { ...LINE_CHART_DEFAULT_OPTIONS, ...this.customOptions }

	@Watch('chartData')
	private async chartDataUpdated(): Promise<void> {
		this.$data._chart.resetZoom()
	}

  async mounted() {
		this.renderChart(this.chartData, this.options)
  }
}
