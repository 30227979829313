<template>
	<tr>
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.begin | date('DD.MM.YYYY HH:mm:ss')}}
		</td>
		<td>
			{{item.end | date('DD.MM.YYYY HH:mm:ss')}}
		</td>
		<td>
			{{item.duration | duration}}
		</td>
		<td>
			{{item.location}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Parking-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
