import { HTTP } from '@/App/Utils/axios'
import { default as dayjs } from 'dayjs'
import { Report, ReportOptions } from './interfaces/report.interface'
import { ExportType } from '@/types'

export class ReportService {
  static async fetch(params: ReportOptions): Promise<Report[]> {
    try {
      const { data } = await HTTP.get('/reports', { params: { ...params } })

      return data
    } catch (e) {
      throw e
    }
  }

  static async download(type: ExportType, reportName: string): Promise<void> {
    try {
      const { data } = await HTTP.get(`/reports/export/${type}`, {
        responseType: 'blob'
      })

      const fileURL = window.URL.createObjectURL(new Blob([data]))
      const fileLink = document.createElement('a')

      const ext = `${type === 'excel' ? 'xlsx' : 'pdf'}`
      const name = `${dayjs().format('MM.DD.YYYY HH:mm')} ${reportName}`.trim()

      fileLink.href = fileURL
      fileLink.setAttribute('download', `${name}.${ext}`)
      document.body.appendChild(fileLink)
      fileLink.click()
      fileLink.remove()
    } catch (e) {
      throw e
    }
  }

  static async print(): Promise<void> {
    try {
      const { data } = await HTTP.get('/reports/export/pdf', {
        responseType: 'blob'
      })

      const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink)
      fileLink.click()
      fileLink.remove()
    } catch (e) {
      throw e
    }
  }
}
