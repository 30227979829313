import { Component, Vue } from 'vue-property-decorator'
import TableHeader from './TableHeader/Table-Header.vue'
import AIThermalReportDetailed from './TableBody/AI-Thermal-Report-Detailed-Table-Body.vue'
import DigitalSensor from './TableBody/Digital-Sensor-Table-Body.vue'
import DoorSensor from './TableBody/Door-Sensor-Table-Body.vue'
import EnergyConsumption from './TableBody/Energy-Consumption-Table-Body.vue'
import NotificationLog from './TableBody/Notification-Log-Table-Body.vue'
import SensorTracing from './TableBody/Sensor-Tracing-Table-Body.vue'
import SummaryInformation from './TableBody/Summary-Information-Table-Body.vue'
import Journey from './TableBody/Journey-Table-Body.vue'
import Geofences from './TableBody/Geofences-Table-Body.vue'
import Stops from './TableBody/Stops-Table-Body.vue'
import Parking from './TableBody/Parking-Table-Body.vue'
import Fuel from './TableBody/Fuel-Table-Body.vue'
import Consumption from './TableBody/Consumption-Table-Body.vue'
import LastSensorValue from './TableBody/Last-Sensor-Value-Table-Body.vue'
import ChartDialog from './ChartDialog/Chart-Dialog.vue'
import { TABLE_HEADERS } from '@/App/Pages/Reports/ReportTable/headers'
import { ReportType } from '@/types'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { ChartData } from 'chart.js'
import i18n from '@/plugins/i18n'


@Component({
  components: {
    Consumption,
    TableHeader,
    AIThermalReportDetailed,
    DigitalSensor,
    DoorSensor,
    EnergyConsumption,
    NotificationLog,
    SensorTracing,
    SummaryInformation,
    Journey,
    Geofences,
    Stops,
    Parking,
    Fuel,
    LastSensorValue,
    ChartDialog
  }
})
export default class ReportTable extends Vue {
  $refs: {
    chartDialog: HTMLFormElement
  }

  public reportName = ''
  public type: ReportType = null
  public search = ''
  public headerInfo: Header = {
    currentDateTime: 0,
    from: 0,
    to: 0
  }
  public isLoading = false
  public headers: VuetifyTableHeader[] = []
  public reportRows: ReportData[] = []
  public customReportInfo: string[] = []

  public fillTable({ type, data, from, to }: Report) {
    this.customReportInfo = []
    if (type) {
      this.headerInfo = {
        currentDateTime: Date.now(),
        from,
        to
      }
      this.type = type
      this.headers = [...TABLE_HEADERS[type]]
      if (type === 'fuel') {
        if (data[0].refuel) {
          this.headers.push({ text: i18n.tc('reportsPage.reportsTable.headers.refuelText'), align: 'center', sortable: true, value: 'refuel' })
        } else {
          this.headers.push({ text: i18n.tc('reportsPage.reportsTable.headers.dispenseText'), align: 'center', sortable: true, value: 'dispense' })
        }
        this.headers.push({ text: i18n.tc('reportsPage.reportsTable.headers.locationText'), align: 'center', sortable: true, value: 'location' })
      }

      this.reportRows = data
      switch (type) {
        case 'consumption':
          this.reportName = this.$t('reportsPage.reportsTable.consumptionText').toString()
          break
        case 'digitalSensor':
          this.reportName = this.$t('reportsPage.reportsTable.digitalSensorText').toString()
          break
        case 'doorSensor':
          this.reportName = this.$t('reportsPage.reportsTable.doorSensorText').toString()
          break
        case 'summaryInformation':
          this.reportName = this.$t('reportsPage.reportsTable.summaryInformationText').toString()
          break
        case 'sensorTracing':
          this.reportName = this.$t('reportsPage.reportsTable.sensorTracingText').toString()
          break
        case 'notificationLog':
          this.reportName = this.$t('reportsPage.reportsTable.notificationLogText').toString()
          break
        case 'energyConsumption':
          this.reportName = this.$t('reportsPage.reportsTable.energyConsumptionText').toString()
          break
        case 'AIThermalReportDetailed':
          this.reportName = this.$t('reportsPage.reportsTable.aiThermalReportDetailedText').toString()
          break
        case 'journey':
          this.reportName = this.$t('reportsPage.reportsTable.journeyText').toString()
          this.customReportInfo = [`Total distance: ${this.reportRows.reduce((a: number, b: any) => a + b.distance, 0).toFixed(2)}`]
          break
        case 'geofences':
          this.reportName = this.$t('reportsPage.reportsTable.geofencesText').toString()
          break
        case 'stops':
          this.reportName = this.$t('reportsPage.reportsTable.stopsText').toString()
          break
        case 'parking':
          this.reportName = this.$t('reportsPage.reportsTable.parkingText').toString()
          break
        case 'fuel':
          this.reportName = this.$t('reportsPage.reportsTable.fuelsText').toString()
          break
        case 'lastSensorValue':
          this.reportName = this.$t('reportsPage.reportsTable.lastSensorIntervalText').toString()
          break
      }
    }
  }

  public openMap(position: [number, number]): void {
    window.open(`https://www.google.com/maps/place/${position.join()}`, '_blank')!.focus()
  }




  public openChart(): void {
    const chartData: ChartData = {
      labels: [...new Set(this.reportRows.map(rr => rr.dateTime))],
      datasets: []
    }
    const widgetsNames = [...new Set(this.reportRows.map(rr => rr.widgetName))]

    widgetsNames.forEach(w => {
      chartData.datasets!.push({
        label: w,
        backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
        fill: false,
        borderColor: '#' + Math.floor(Math.random()*16777215).toString(16),
        data: this.reportRows.filter(rr => rr.widgetName === w).map(rr => +rr.value)
      })
    })

    this.$refs.chartDialog.open(chartData)
  }

}


interface Header {
  currentDateTime: number
  from: number
  to: number
}

export interface Report {
  type: ReportType
  data: ReportData[]
  from: number
  to: number
}

interface ReportData {
  [key: string]: string
}
