<template>
	<tr>
		<td>
			{{item.startTime | date('DD.MM.YYYY HH:MM:ss')}}
		</td>
		<td>
			{{item.endTime | date('DD.MM.YYYY HH:MM:ss')}}
		</td>
		<td>
			{{item.startAddress}}
		</td>
		<td>
			{{item.startLatLng}}
		</td>
		<td>
			{{item.endAddress}}
		</td>
		<td>
			{{item.endLatLng}}
		</td>
		<td>
			{{item.distance}}
		</td>
		<td>
			{{item.duration | duration}}
		</td>
		<td>
			{{item.maxSpeed}}
		</td>
		<td>
			{{item.minSpeed}}
		</td>
		<td>
			{{item.averageSpeed}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Journey-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
