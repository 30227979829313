import { HTTP } from '@/App/Utils/axios'
import { WidgetForm, Widget } from '@/store/interfaces/widget.interface'
import {
  WidgetLayoutUpdateRequest,
  WidgetLayoutUpdateResponse,
  WidgetUpdateRequest
} from '@/App/Services/interfaces/widget.interface'

export class WidgetService {
  static async fetch(dashboardId: string): Promise<Widget[]> {
    try {
      const { data } = await HTTP.get(`/dashboard/${dashboardId}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async findAll(): Promise<Widget[]> {
    try {
      const { data } = await HTTP.get('dashboard')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(dashboardId: string, widget: WidgetForm): Promise<Widget> {
    try {
      const { data } = await HTTP.post(`/dashboard/${dashboardId}`, widget)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(dashboardId: string, widgetId: string, widget: WidgetUpdateRequest): Promise<Widget> {
    try {
      const { data } = await HTTP.put(`/dashboard/${dashboardId}/widgets/${widgetId}`, widget)

      return data
    } catch (e) {
      throw e
    }
  }

  static async copy(dashboardId: string, widgetId:string): Promise<Widget> {
    try {
      const { data } = await HTTP.post(`/dashboard/${dashboardId}/widgets/${widgetId}/copy`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async updateLayout(dashboardId: string, widgetId:string, layout: WidgetLayoutUpdateRequest): Promise<WidgetLayoutUpdateResponse> {
    try {
      const { data } = await HTTP.put(`/dashboard/${dashboardId}/widgets/${widgetId}/layout`, layout)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(dashboardId: string, widgetId: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/dashboard/${dashboardId}/widgets/${widgetId}`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchByDashboardId(dashboardId: string): Promise<Widget[]> {
    try {
      const { data } = await HTTP.get(`/dashboard/${dashboardId}/widgets`)

      return data
    } catch (e) {
      throw e
    }
  }
}
