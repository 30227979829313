import { Vue, Component } from 'vue-property-decorator'
import { ChartData } from 'chart.js'
import { LineChart } from '@/App/Components/Charts'

@Component({
  components: { LineChart }
})
export default class ChartDialog extends Vue {
  public isOpen = false
  public chartData: ChartData | null = null

  public open(chartData: ChartData): void {
    this.isOpen = true

    setTimeout(() => this.chartData = chartData, 500)
  }
}

