import { VuetifyTableHeader } from '@/interfaces/vuetify'
import i18n from '@/plugins/i18n'


export const TABLE_HEADERS: TableHeader = {
  consumption: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.startTimeText'), align: 'center', sortable: true, value: 'startTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.endTimeText'), align: 'center', sortable: true, value: 'endTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.consumptionText'), align: 'center', sortable: true, value: 'consumption' }
  ],
  digitalSensor: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceText'), align: 'left', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.widgetText'), align: 'left', sortable: true, value: 'widgetName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.activationAtText'), align: 'left', sortable: true, value: 'activatedTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.deactivatedAtText'), align: 'left', sortable: true, value: 'deactivatedTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.durationText'), align: 'left', sortable: true, value: 'activatedDuration' }
  ],
  doorSensor: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.sensorNameText'), align: 'center', sortable: true, value: 'widgetName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.openedTimeText'), align: 'center', sortable: true, value: 'openedTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.closedTimeText'), align: 'center', sortable: true, value: 'closedTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.openedDurationText'), align: 'center', sortable: true, value: 'openedDuration' }
  ],
  summaryInformation: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.sensorNameText'), align: 'center', sortable: true, value: 'widgetName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.averageValueText'), align: 'center', sortable: true, value: 'averageValue' },
    { text: i18n.tc('reportsPage.reportsTable.headers.lowestValueText'), align: 'center', sortable: true, value: 'lowestValue' },
    { text: i18n.tc('reportsPage.reportsTable.headers.highestValueText'), align: 'center', sortable: true, value: 'highestValue' }
  ],
  sensorTracing: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.sensorNameText'), align: 'center', sortable: true, value: 'widgetName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.timeText'), align: 'center', sortable: true, value: 'dateTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.sensorValueText'), align: 'center', sortable: true, value: 'value' }
  ],
  notificationLog: [
    { text: i18n.tc('reportsPage.reportsTable.headers.textText'), align: 'center', sortable: true, value: 'text' },
    { text: i18n.tc('reportsPage.reportsTable.headers.createdAtText'), align: 'center', sortable: true, value: 'dtCreate' },
    { text: i18n.tc('reportsPage.reportsTable.headers.viewedAtText'), align: 'center', sortable: true, value: 'dtViewed' }
  ],
  energyConsumption: [
    { text: i18n.tc('reportsPage.reportsTable.headers.powerMeterIdText'), align: 'center', sortable: true, value: 'powerMeterId' },
    { text: i18n.tc('reportsPage.reportsTable.headers.consumedKwHText'), align: 'center', sortable: true, value: 'consumedKwH' },
    { text: i18n.tc('reportsPage.reportsTable.headers.amountText'), align: 'center', sortable: true, value: 'amount' }
  ],
  AIThermalReportDetailed: [
    { text: i18n.tc('reportsPage.reportsTable.headers.timestampText'), align: 'center', sortable: true, value: 'timestamp' },
    { text: i18n.tc('reportsPage.reportsTable.headers.personUuidText'), align: 'center', sortable: true, value: 'personUuid' },
    { text: i18n.tc('reportsPage.reportsTable.headers.userIdText'), align: 'center', sortable: true, value: 'userId' },
    { text: i18n.tc('reportsPage.reportsTable.headers.nameText'), align: 'center', sortable: true, value: 'name' },
    { text: i18n.tc('reportsPage.reportsTable.headers.groupIdText'), align: 'center', sortable: true, value: 'groupId' },
    { text: i18n.tc('reportsPage.reportsTable.headers.attrGender'), align: 'center', sortable: true, value: 'attrGender' },
    { text: i18n.tc('reportsPage.reportsTable.headers.gender'), align: 'center', sortable: true, value: 'gender' },
    { text: i18n.tc('reportsPage.reportsTable.headers.attrAge'), align: 'center', sortable: true, value: 'attrAge' },
    { text: i18n.tc('reportsPage.reportsTable.headers.ageText'), align: 'center', sortable: true, value: 'age' },
    { text: i18n.tc('reportsPage.reportsTable.headers.temperatureText'), align: 'center', sortable: true, value: 'Temperature' },
    { text: i18n.tc('reportsPage.reportsTable.headers.maskText'), align: 'center', sortable: true, value: 'Mask' },
    { text: i18n.tc('reportsPage.reportsTable.headers.statusText'), align: 'center', sortable: true, value: 'status' }
  ],
  journey: [
    { text: i18n.tc('reportsPage.reportsTable.headers.startTimeText'), align: 'center', sortable: true, value: 'startTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.endTimeText'), align: 'center', sortable: true, value: 'endTime' },
    { text: i18n.tc('reportsPage.reportsTable.headers.initialLocationText'), align: 'center', sortable: true, value: 'startAddress' },
    { text: i18n.tc('reportsPage.reportsTable.headers.initialCoordinatesText'), align: 'center', sortable: true, value: 'startLatLng' },
    { text: i18n.tc('reportsPage.reportsTable.headers.finalLocationText'), align: 'center', sortable: true, value: 'endAddress' },
    { text: i18n.tc('reportsPage.reportsTable.headers.finalCoordinatesText'), align: 'center', sortable: true, value: 'endLatLng' },
    { text: i18n.tc('reportsPage.reportsTable.headers.traveledDistanceText'), align: 'center', sortable: true, value: 'distance' },
    { text: i18n.tc('reportsPage.reportsTable.headers.durationText'), align: 'center', sortable: true, value: 'duration' },
    { text: i18n.tc('reportsPage.reportsTable.headers.maxSpeedText'), align: 'center', sortable: true, value: 'maxSpeed' },
    { text: i18n.tc('reportsPage.reportsTable.headers.minSpeedText'), align: 'center', sortable: true, value: 'minSpeed' },
    { text: i18n.tc('reportsPage.reportsTable.headers.averageSpeedText'), align: 'center', sortable: true, value: 'averageSpeed' }
  ],
  geofences: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.geofenceNameText'), align: 'center', sortable: true, value: 'geofenceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.timeInText'), align: 'center', sortable: true, value: 'timeIn' },
    { text: i18n.tc('reportsPage.reportsTable.headers.timeOutText'), align: 'center', sortable: true, value: 'timeOut' },
    { text: i18n.tc('reportsPage.reportsTable.headers.durationText'), align: 'center', sortable: true, value: 'duration' },
    { text: i18n.tc('reportsPage.reportsTable.headers.descriptionText'), align: 'center', sortable: true, value: 'geofenceDescription' }
  ],
  stops: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.startText'), align: 'center', sortable: true, value: 'begin' },
    { text: i18n.tc('reportsPage.reportsTable.headers.endText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.durationText'), align: 'center', sortable: true, value: 'duration' },
    { text: i18n.tc('reportsPage.reportsTable.headers.locationText'), align: 'center', sortable: true, value: 'location' }
  ],
  parking: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.startText'), align: 'center', sortable: true, value: 'begin' },
    { text: i18n.tc('reportsPage.reportsTable.headers.endText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.durationText'), align: 'center', sortable: true, value: 'duration' },
    { text: i18n.tc('reportsPage.reportsTable.headers.locationText'), align: 'center', sortable: true, value: 'location' }
  ],
  fuel: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'deviceName' },
    { text: i18n.tc('reportsPage.reportsTable.headers.timeEndText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.fuelBeforeText'), align: 'center', sortable: true, value: 'before' },
    { text: i18n.tc('reportsPage.reportsTable.headers.fuelAfterText'), align: 'center', sortable: true, value: 'after' }
  ],
  lastSensorValue: [
    { text: i18n.tc('reportsPage.reportsTable.headers.deviceNameText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.widgetText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.startText'), align: 'center', sortable: true, value: 'end' },
    { text: i18n.tc('reportsPage.reportsTable.headers.sensorValueText'), align: 'center', sortable: true, value: 'end' }
  ]
}

interface TableHeader {
  [key: string]: VuetifyTableHeader[]
}
