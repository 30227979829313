<template>
	<tr class="app-bg-color-main">
		<td>
			{{item.deviceName}}
		</td>
		<td>
			{{item.begin | date('DD.MM.YYYY HH:mm')}}
		</td>
		<td>
			{{item.end | date('DD.MM.YYYY HH:mm')}}
		</td>
		<td>
			{{item.consumption}}
		</td>
	</tr>
</template>

<script>
export default {
  name: 'Consumption-Table-Body',
  props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
