<template>
	<tr>
		<td>
			{{item.text}}
		</td>
		<td>
			{{item.dtCreate}}
		</td>
		<td>
			{{item.dtViewed}}
		</td>
	</tr>
</template>

<script>
export default {
	name: 'Notification-Log-Table-Body',
	props: ['item']
}
</script>
<style lang="scss" src="./styles.scss" scoped />
