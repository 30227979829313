import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'
import { GeofenceService } from '@/App/Services/Geofence.service'

const sharedStore = namespace('Shared')

@Component
export default class GeofenceSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop({ default: false })
  public readonly required!: boolean

  @Prop({ default: false })
  public readonly multiple!: boolean

  @Prop({ default: false })
  public dark: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public geofenceId: string | string[] = []
  public geofencesItems: VuetifySelect[] = []
  public isLoading = false

  @Watch('value')
  private valueChanged(val: string): void {
    if (this.multiple) {
      this.geofenceId = []
      this.geofenceId = val
    } else {
      this.geofenceId = ''
      this.geofenceId = val
    }
  }

  @Watch('geofencesItems')
  private deviceItemsChanged(): void {
    if (this.multiple) {
      this.geofenceId = []
      this.geofenceId = this.value
    } else {
      this.geofenceId = ''
      this.geofenceId = this.value || ''
    }
  }

  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    if (!this.multiple && !Array.isArray(this.geofenceId)) {
      return this.validator.isEmpty(this.geofenceId)
    } else {
      if (Array.isArray(this.geofenceId) && !this.geofenceId.length) {
        return this.$t('geofenceSelectComponent.validationError').toString()
      } else {
        return true
      }
    }
  }

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      const geofences = await GeofenceService.fetch()

      this.geofencesItems = geofences.map(g => {
        return { value: g._id, text: g.name }
      })
      if (this.multiple) {
        this.geofenceId = []
        this.geofenceId = this.value
      } else {
        this.geofenceId = ''
        this.geofenceId = this.value || ''
      }
    } catch {} finally {
      this.isLoading = false
    }
  }
}
